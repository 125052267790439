import React from "react";
import ServicePage from "../../components/ServicePage";
import PageMeta from "../../components/PageMeta";

export default function ShoppingPage() {
  return (
    <ServicePage>
      <PageMeta title="QCL Services - Shopping" path="services/shopping" />
      <h1 className="top">Shopping</h1>
      <p className="lead">
        For Service Users who are unable to do their own grocery shopping, QCL
        provide a regular shopping service.
      </p>
      <p>
        QCL carers are instructed in “shopping procedures” as part of their
        initial induction.
      </p>
      <p>
        The cost of this service varies but, typically, one and half hours are
        usually charged.
      </p>
      <p>Features of this service are:-</p>
      <ul>
        <li>Assistance to draw up a shopping list.</li>
        <li>Fridge management, including checking of "use-by" dates.</li>
        <li>Liaison with family members where applicable.</li>
        <li>Cash handling and management of receipts.</li>
      </ul>
      <p>
        QCL usually provide a shopping service as part of a more complex support
        plan.
      </p>
      <p>
        For further information, please check the{" "}
        <a href="/pdf/QCL-Shopping-Procedure.pdf">QCL Shopping Procedure</a>.
      </p>
    </ServicePage>
  );
}
